.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 4px;
}
.p-2 {
  padding: 8px;
}
.p-3 {
  padding: 12px;
}
.p-4 {
  padding: 16px;
}
.p-5 {
  padding: 20px;
}
.p-6 {
  padding: 24px;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 4px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-3 {
  padding-top: 12px;
}

.pt-6 {
  padding-top: 24px;
}
.pt-10 {
  padding-top: 40px;
}
.pt-4 {
  padding-top: 16px;
}
.p-6 {
  padding: 24px;
}
.pt-16 {
  padding-top: 64px;
}
.pt-20 {
  padding-top: 80px;
}
.pt-25 {
  padding-top: 100px;
}
.pt-30 {
  padding-top: 120px;
}
.pt-35 {
  padding-top: 140px;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 4px;
  padding-left: 4px;
}
.px-2 {
  padding-right: 8px;
  padding-left: 8px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-4 {
  padding-right: 16px;
  padding-left: 16px;
}
.px-5 {
  padding-right: 20px;
  padding-left: 20px;
}
.px-6 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-10 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-15 {
  padding-right: 60px;
  padding-left: 60px;
}

.px-20 {
  padding-right: 80px;
  padding-left: 80px;
}

.px-30 {
  padding-right: 120px;
  padding-left: 120px;
}

.px-45 {
  padding-right: 180px;
  padding-left: 180px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-7 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.py-9 {
  padding-top: 36px;
  padding-bottom: 36px;
}
.py-10 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-15 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-20 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pb-4 {
  padding-bottom: 16px;
}
.pb-6 {
  padding-bottom: 24px;
}
.pb-10 {
  padding-bottom: 40px;
}
.pb-13 {
  padding-bottom: 52px;
}
.pb-15 {
  padding-bottom: 60px;
}
.pb-20 {
  padding-bottom: 80px;
}
.pb-30 {
  padding-bottom: 120px;
}
.pb-35 {
  padding-bottom: 140px;
}

.pb-40 {
  padding-bottom: 160px;
}

.pl-3 {
  padding-left: 12px;
}
.pl-5 {
  padding-left: 20px;
}
.pl-6 {
  padding-left: 24px;
}
.pl-8 {
  padding-left: 32px;
}
.pl-10 {
  padding-left: 40px;
}
.pl-20 {
  padding-left: 80px;
}
.pl-40 {
  padding-left: 160px;
}

.pr-1 {
  padding-right: 4px;
}
.pr-2 {
  padding-right: 8px;
}
.pr-3 {
  padding-right: 12px;
}
.pr-4 {
  padding-right: 16px;
}
.pr-5 {
  padding-right: 20px;
}
.pr-6 {
  padding-right: 24px;
}
.pr-10 {
  padding-right: 40px;
}
.pr-20 {
  padding-right: 80px;
}
.pr-30 {
  padding-right: 120px;
}
.pr-35 {
  padding-right: 145px;
}
.pr-40 {
  padding-right: 160px;
}
.m-0 {
  margin: 0;
}
.m-auto {
  margin: auto;
}
.m-1 {
  margin: 4px;
}
.m-2 {
  margin: 8px;
}
.m-3 {
  margin: 8px;
}
.m-4 {
  margin: 8px;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-7 {
  margin-top: 28px;
  margin-bottom: 28px;
}
.my-8 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.my-9 {
  margin-top: 36px;
  margin-bottom: 36px;
}
.my-10 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}
.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-3 {
  margin-right: 12px;
  margin-left: 12px;
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}
.mx-10 {
  margin-right: 40px;
  margin-left: 40px;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 4px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-5 {
  margin-top: 20px;
}
.mt-6 {
  margin-top: 24px;
}
.mt-7 {
  margin-top: 28px;
}
.mt-8 {
  margin-top: 32px;
}
.mt-9 {
  margin-top: 36px;
}
.mt-10 {
  margin-top: 40px;
}
.mt-11 {
  margin-top: 44px;
}
.mt-12 {
  margin-top: 48px;
}
.mt-16 {
  margin-top: 64px;
}
.mt-25 {
  margin-top: 100px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-5 {
  margin-right: 20px;
}
.mr-6 {
  margin-right: 24px;
}
.mr-10 {
  margin-right: 40px;
}
.mr-19 {
  margin-right: 76px;
}
.ml-auto {
  margin-left: auto;
}
.ml-1 {
  margin-left: 4px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-6 {
  margin-left: 24px;
}
.mb-auto {
  margin-bottom: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mb-6 {
  margin-bottom: 24px;
}
.mb-7 {
  margin-bottom: 28px;
}
.mb-8 {
  margin-bottom: 32px;
}
.mb-9 {
  margin-bottom: 36px;
}
.mb-10 {
  margin-bottom: 40px;
}
.mb-12 {
  margin-bottom: 48px;
}
.mb-13 {
  margin-bottom: 52px;
}
.mb-20 {
  margin-bottom: 80px;
}
.mb-35 {
  margin-bottom: 140px;
}
