html,
body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}
a {
  color: #096dd9;
}
.pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.green-6 {
  color: @green-6;
}

.family-sans {
  font-family: Inter, sans-serif;
  font-style: normal;
}

.poster {
  background-image: url("/assets/images/sidebar-image-aurora-aktiva.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.max-w-full {
  max-width: 100%;
}
.max-w-screen {
  max-width: 100vh;
}
.w-12 {
  width: 48px;
}
.w-full {
  width: 100%;
}
.min-h-100vh {
  min-height: 100vh;
}
.max-h-full {
  max-height: 100%;
}
.max-h-screen {
  max-height: 100vh;
}
.max-w-280 {
  max-width: 280px;
}
.max-w-348 {
  max-width: 348px;
}
.max-w-400 {
  max-width: 400px;
}
.max-w-1512 {
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
}

.max-w-1380 {
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
}

.max-h-1619 {
  max-height: 1619px;
}

.max-h-1818 {
  max-height: 1818px;
}

.base-layout {
  background: #fff;
  margin: 0 auto;
}

.bg-white {
  background: white;
}

.detail-layout {
  margin: 0 auto;
}

.detail-image-props {
  width: 100%;
  height: auto;
  object-fit: contain;
  flex-shrink: 0;
}

.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-navbar {
  height: 55px;
}
.h-screen {
  height: 100vh;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.opacity-1 {
  opacity: 1;
}
.anchor-primary,
.blue-6 {
  color: #1890ff;
}
.text-white {
  color: #fff;
}
.text-primary {
  color: #000000d9;
}
.text-secondary {
  color: #00000073;
}
.text-navbar {
  color: #141414;
}
.text-danger {
  color: #ff4d4f;
}
.text-warning {
  color: #faad14;
}
.text-success {
  color: #0d9f3f;
}
.text-info {
  color: #1890ff;
}
.text-3xs {
  font-size: 8px;
  line-height: 20px;
}
.text-2xs {
  font-size: 10px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 20px;
}
.text-sm {
  font-size: 14px;
  line-height: 22px;
}
.text-base {
  font-size: 16px;
  line-height: 24px;
}
.text-lg {
  font-size: 18px;
  line-height: 28px;
}
.text-xl {
  font-size: 20px;
  line-height: 28px;
}
.text-2xl {
  font-size: 24px;
  line-height: 32px;
}
.text-2-xl {
  font-size: 28px;
  line-height: 36px;
}
.text-3-xl {
  font-size: 30px;
  line-height: 40px;
}
.text-3xl {
  font-size: 32px;
  line-height: 40px;
}

.text-4xl {
  font-size: 40px;
  line-height: 52px;
}

.leading-none {
  line-height: 1;
}

.leading-7 {
  line-height: 28px !important;
}
.underline {
  text-decoration-line: underline;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.bg-white {
  background-color: #fff;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom-center {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}
@media (max-width: 1199px) {
  .block-lg {
    display: block;
  }
}
@media (max-width: 991px) {
  .block-md {
    display: block;
  }
}
@media (max-width: 767px) {
  .block-xs {
    display: block;
  }
}
@media (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

.verifikasi-user {
  .flow-user {
    max-width: 472px;
  }

  .fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.footer-height {
  height: 178px;
}
.forget-anchor {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.register-anchor {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.login-form {
  display: flex;
  flex-direction: column;
}
.title-form {
  margin-bottom: 4px;
}
.logo-aktiva {
  padding: 2.5rem 2.5rem;
}
.form-login {
  max-width: 348px;
  width: 100%;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px;
}
.form-text .ant-form-item-label {
  padding: 0 0 4px;
}
.form-text .ant-form-item-label > label {
  font-weight: 400;
  line-height: 22px;
}
.form-login-member {
  margin: auto;
  width: 100%;
  max-width: 348px;
}
.btn {
  background-color: #0d9f3f;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  border-color: #0d9f3f;
}
.btn:focus,
.btn:active {
  background: #057b2d;
  border-color: #0d9f3f;
}
.btn:hover {
  animation-delay: 1ms;
  animation-timing-function: ease-in-out;
  animation-duration: 600ms;
  background: #3dd370;
  border-color: #3dd370;
}
.form-login .ant-form-item-label {
  padding: 0 0 4px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

:where(.css-dev-only-do-not-override-1lnyyay).dot-after
  .ant-form-vertical
  .ant-form-item-label
  > label::after,
:where(.css-dev-only-do-not-override-1lnyyay).dot-after
  .ant-col-24.ant-form-item-label
  > label::after,
:where(.css-dev-only-do-not-override-1lnyyay).dot-after
  .ant-col-xl-24.ant-form-item-label
  > label::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  content: "*";
  visibility: visible;
}

:where(.css-dev-only-do-not-override-zc1svm).policy-form
  .ant-form-item
  .ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-modal .ant-modal-footer {
  margin-top: 32px;
}

.hide-padding .ant-col-24.ant-form-item-label {
  padding: 0;
}

.detail-gap {
  margin-left: 60px;
}

.collapse-navbar.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

@media (max-width: 767px) {
  .detail-gap {
    margin-left: 0;
  }
}

.dropdown-hamburger.ant-dropdown .ant-dropdown-menu {
  padding: 0;
}

.dropdown-hamburger.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-image-container {
  position: relative;
}

.card-filter.ant-card .ant-card-body {
  padding: 24px;
}

.ant-table-wrapper .ant-table-cell.image-cell {
  padding: 8px 16px;
}

.custom-meta.ant-card .ant-card-actions > li {
  margin: 8px 0;
}
.custom-meta.ant-card .ant-card-body {
  padding: 12px 0;
}
.image-cover {
  transition: filter 0.3s ease-in-out;
}

.image-cover:hover {
  filter: brightness(70%);
}

.btn-setup {
  width: 348px;
}

.btn-list {
  border-color: #0d9f3f;
}

.hover-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card-image-container:hover .image-cover {
  filter: brightness(70%);
}

.card-image-container:hover .hover-button-container {
  opacity: 5;
}

.custom-cancel-button:hover {
  color: #0d9f3f !important;
  border-color: #0d9f3f !important;
}

.custom-cancel-btn:hover {
  color: #0d9f3f !important;
  border: 1px solid #0d9f3f !important;
}

.btn-back-editor {
  background-color: #1f1f1f;
  border: 0;
}

.ant-btn.btn-cancel {
  border: 1px solid var(--character-danger, #ff4d4f);
  color: var(--character-danger, #ff4d4f);
}

.ant-btn.ant-btn-default.btn-cancel:hover {
  border: 1px solid var(--character-danger, #ff7875);
  color: var(--character-danger, #ff7875);
}

.ant-btn-primary {
  color: white;
  background-color: #0d9f3f;
}

.ant-btn-primary:hover {
  background-color: #29ab52 !important;
}

.ant-btn-danger {
  color: white;
  background-color: #ff4d4f;
}

.ant-btn-danger:hover {
  border: 1px solid var(--character-danger, #ff4d4f);
  background: var(--dust-red-4, #ff7875);
}

.ant-card .ant-card-body {
  padding: 20px 24px;
}

.ant-card .ant-card-body .template-card {
  padding: 20px 24px;
}

.ant-table-wrapper .ant-table-title {
  padding: 16px 0;
}

:where(.css-dev-only-do-not-override-1lnyyay).ant-form-vertical
  .ant-form-item-label,
:where(.css-dev-only-do-not-override-1lnyyay).ant-col-24.ant-form-item-label,
:where(
    .css-dev-only-do-not-override-1lnyyay
  ).ant-col-xl-24.ant-form-item-label {
  padding: 0;
}

.auth-form {
  max-width: 472px;
}
.text-btn-primary {
  color: #0d9f3f;
}
.text-btn-primary:hover {
  color: #0d9f3f;
}
.text-btn-secondary {
  color: #00000073;
}
.text-btn-secondary:hover {
  color: #00000073;
}

.card-Mytemplate.ant-card .ant-card-head {
  padding: 24px;
}

.title-list {
  padding: 0 8px 32px;
}

.main-drawer {
  margin-bottom: 84px;
}
.ant-table-title {
  padding-inline: 0px;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #0d9f3f;
}

.ant-pagination .ant-pagination-item-active a {
  color: #0d9f3f;
}

.ant-table-wrapper .ant-table-title {
  padding-top: 0;
}

.ant-switch.ant-switch-checked {
  background: #0d9f3f;
}

.ant-tag-processing {
  border: 1px solid var(--primary-3, #91d5ff) !important;
  background: var(--primary-1, #e6f7ff) !important;
  color: #1890ff !important;
}

.footer-text {
  color: white;
  font-family: Inter, sans-serif;
}

.font-inter {
  font-family: Inter, sans-serif !important;
}

.inactive-link {
  line-height: 28px !important;
  font-weight: 400;
}

.inactive-link:hover {
  color: inherit;
}

.box-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 880px;
}

.flow-user {
  max-width: 472px;
}

.container-step {
  width: 400px;
}

.card-blank.ant-card .ant-card-body {
  padding: 32px 24px;
}

.card-choose.ant-card .ant-card-body {
  padding: 0 !important;
}

.latest-template.ant-card .ant-card-body {
  padding: 16px 24px;
}

.modal-manage.ant-modal .ant-modal-content {
  padding: 24px;
}

.image-scrollable {
  height: 736px;
  overflow-y: auto;
}

.icon-list.anticon svg {
  font-size: 14px;
}

.modal-image.ant-modal .ant-modal-footer {
  margin-top: 0;
}

.modal-image.ant-modal .ant-modal-content {
  margin-top: 80px;
  margin-bottom: 40px;
}

.modal-image-container {
  height: auto;
  overflow-y: auto;
}

.modal-import.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #18c753;
}

.card-users.ant-card .ant-card-body {
  padding: 24px;
}

.modal-confirm-delete.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
}

.profile-pic.ant-card .ant-card-body {
  padding: 24px;
}

.summary.ant-card .ant-card-body {
  padding: 16px 24px;
}

.list-email.ant-card .ant-card-body {
  padding: 24px;
}

.blank-latest.ant-card .ant-card-body {
  padding: 24px 20px 32px;
}

.blank-latest-medium.ant-card .ant-card-body {
  padding: 24px 12px 32px;
}

.desc-company .ant-descriptions-row {
  box-shadow: inset 0px -1px 0px #f0f0f0;
  & > td {
    padding-top: 8px;
    padding-bottom: 12px;
  }
}
